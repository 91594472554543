import React from 'react'

const Reviewg = () => {

    const reviews = [
        {
          name: "Soma Sadhukhan",
         
          text: "I keep purchasing crystals from here, the quality is very good , very cooperative & quick delivery.. the packaging is also very nice.",
          rating: 5,
        },
        {
          name: "Partho Dey",
         
          text: "I have recently bought Red coral gemstone from Lakshya Gems and trust me its purely original and affordable as well. I would 100% recommend the outlet for ones who's looking for genuine gems or crystals.",
          rating: 5,
        },
        {
          name: "Saurabh Srivastava",
         
          text: "My experience with Lakshya Gems was excellent. Their crystals are authentic, and their service is prompt and friendly. Highly recommend for premium crystals and gemstones!",
          rating: 5,
        },
      ];

  return (
    <div className="container mt-5">
      <div className="text-center mb-4">
        <h3>Lakshya Gems</h3>
        <div className="d-flex justify-content-center align-items-center">
          <img
            src="https://cdn.grw.reputon.com/img/google_g_logo.svg"
            alt="Google"
            style={{ height: "30px", marginRight: "10px" }}
          />
          <span style={{ fontSize: "1.5rem" }}>4.9</span>
          <div className="ml-2">
            {[...Array(5)].map((_, idx) => (
              <span key={idx} style={{ color: "#f39c12", fontSize: "1.5rem" }}>
                ★
              </span>
            ))}
          </div>
        </div>
        <p className="text-muted">powered by Google</p>
      </div>
      <div className="row">
        {reviews.map((review, index) => (
          <div key={index} className="col-md-4 mb-4">
            <div className="card h-100 p-3">
              <div className="d-flex justify-content-between">
                <div>
                  {[...Array(review.rating)].map((_, idx) => (
                    <span
                      key={idx}
                      style={{ color: "#f39c12", fontSize: "1.5rem" }}
                    >
                      ★
                    </span>
                  ))}
                </div>
                <div>
                  <img
                    src="https://cdn.grw.reputon.com/img/google_g_logo.svg"
                    alt="Google"
                    style={{ height: "20px" }}
                  />
                </div>
              </div>
              <p className="mt-2">{review.text}</p>
              <div className="d-flex align-items-center gap-2">
                <img
                  src={`https://i.pravatar.cc/50?img=${index + 1}`}
                  alt="Avatar"
                  className="rounded-circle mr-2"
                  style={{ height: "30px" }}
                />
                <div>
                  <p className="mb-0" style={{ fontSize: "0.9rem" }}>
                    {review.name}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Reviewg