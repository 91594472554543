import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { ProductContext } from '../ProductContext';
import style from '../css/Products.css';
import Productss from './Productss';

const Products = () => {
    const { products } = useContext(ProductContext);

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      };

    return (
        <>
         {/* <Productss/> */}
            <div className="container-fluid my-5">
                <div className="row">
                    <div className="col-md-12">
                        <h3 className='text-center'>Products</h3>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row my-4 justify-content-center">
                    {products.map(product => (
                        <div key={product.id} className="col-6 col-md-4 col-lg-3 d-flex flex-column category_box">
                            <NavLink to={`/Productdescription/${product.id}`} onClick={scrollToTop} className="text-decoration-none">
                                <div className="product_box">
                                    <img src={product.image} alt={product.name} className='product_img img-fluid' />
                                    <p>{product.name}</p>
                                    <p className='price_name'>₹{product.new_price}</p>
                                </div>
                            </NavLink>
                        </div>
                    ))}
                </div>
            </div>
           
        </>
    );
}

export default Products;



