import React, { useContext } from 'react';
import { ProductContext } from '../ProductContext';
import style from '../css/Products.css';
import { NavLink } from 'react-router-dom';

const Productss = () => {
    const { filteredProducts } = useContext(ProductContext);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <div className="container">
            <div className="row justify-content-center">
                {filteredProducts.length > 0 ? (
                    filteredProducts.map(product => (
                        <div key={product.id} className="col-6 col-md-4 col-lg-3 d-flex flex-column category_box">
                            <NavLink to={`/Productdescription/${product.id}`} onClick={scrollToTop} className="text-decoration-none">
                                <div className="product_box">
                                    <img src={product.image} alt={product.name} className='product_img img-fluid' />
                                    <p>{product.name}</p>
                                    <p className='price_name'>₹{product.new_price}</p>
                                </div>
                            </NavLink>
                        </div>
                    ))
                ) : (
                    <div className="col-12 text-center">
                        <h3 className='py-5'>Sorry, no products matched your search...</h3>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Productss;
