

// import React, { useContext } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { ProductContext } from '../ProductContext';
// import axios from 'axios';
// import '../css/Addressdetails.css';

// const Addressdetails = () => {
//   const { cartItems = [], clearCart } = useContext(ProductContext);
//   const navigate = useNavigate();

//   const calculateTotal = () => {
//     return cartItems.reduce((total, item) => total + item.new_price * item.quantity, 0) + 80;
//   };

//   const handlePay = async (e) => {
//     e.preventDefault();
//     const formData = new FormData(e.target);
//     const data = {
//       transactionId: `TXN${Date.now()}`,
//       MUID: 'user-id-here',
//       email: formData.get('email'),
//       firstName: formData.get('firstName'),
//       lastName: formData.get('lastName'),
//       pincode: formData.get('pincode'),
//       phoneNumber: formData.get('phoneNumber'),
//       address: formData.get('address'),
//       city: formData.get('city'),
//       state: formData.get('state'),
//       cartItems: cartItems.map(item => ({
//         name: item.name,
//         quantity: item.quantity,
//         price: item.new_price * item.quantity
//       })),
//       amount: calculateTotal(),
//     };
  
//     try {
//       const token = localStorage.getItem('auth-token');
//       const res = await axios.post('${BACKEND_URL}/submitAddress', data, {
//         headers: { 'auth-token': token }
//       });

//       if (res.data && res.data.data && res.data.data.instrumentResponse && res.data.data.instrumentResponse.redirectInfo) {
//         const redirectUrl = res.data.data.instrumentResponse.redirectInfo.url;
//         if (redirectUrl) {
//           window.location.href = redirectUrl;
//         } else {
//           console.error('Redirect URL not found');
//         }
//       } else {
//         console.error('Unexpected response structure:', res.data);
//       }
//     } catch (error) {
//       console.error('Payment error:', error);
//     }
//     clearCart();
//   };

//   return (
//     <div className="container py-5">
//        <div className="row">
//          <form onSubmit={handlePay}>
//            <div className="col-md-12">
//              <div className="row">
//                <div className="col-md-8">
//                  <h3 className="py-3">Add Shipping Address</h3>
//                  <input type="email" className="w-100 p-2 my-2" placeholder="Email" name="email" required />
//                  <div className="row">
//                    <div className="col-md-6">
//                      <input type="text" className="w-100 p-2 my-2" placeholder="First Name" name="firstName" required />
//                    </div>
//                    <div className="col-md-6">
//                      <input type="text" className="w-100 p-2 my-2" placeholder="Last Name" name="lastName" required />
//                    </div>
//                    <div className="col-md-6">
//                      <input type="number" className="w-100 p-2 my-2" placeholder="Pincode" name="pincode" required />
//                    </div>
//                    <div className="col-md-6">
//                      <input type="number" className="w-100 p-2 my-2" placeholder="Phone Number" name="phoneNumber" required />
//                    </div>
//                  </div>
//                  <textarea className="w-100 p-2 my-2" placeholder="Address" name="address" required></textarea>
//                  <div className="row">
//                    <div className="col-md-6">
//                      <input type="text" className="w-100 p-2 my-2" placeholder="City" name="city" required />
//                    </div>
//                    <div className="col-md-6">
//                      <input type="text" className="w-100 p-2 my-2" placeholder="State" name="state" required />
//                    </div>
//                  </div>
//                </div>
//                {/* {localStorage.getItem('auth-token') ? ( */}
//                <div className="col-md-4 ordersummary_box">
//                  <h3>Order Summary</h3>
//                  <div className="row">
//                    {cartItems.map((item, index) => (
//                      <React.Fragment key={index}>
//                        <div className="col-md-12 py-4">
//                          <div className="row">
//                            <div className="col-md-4">
//                              <img src={item.image} alt={item.name} className="img-fluid cart_img" style={{ width: '100%' }} />
//                            </div>
//                            <div className="col-md-8">
//                              <h5>{item.name}</h5>
//                              <h6>Quantity: {item.quantity}</h6>
//                              <h4>₹{item.new_price * item.quantity}</h4>
//                            </div>
//                          </div>
//                        </div>
//                        <hr />
//                      </React.Fragment>
//                    ))}
//                    <div className="d-flex gap-5">
//                      <div className="cart_text">
//                        <h6>Shipping Fee:</h6>
//                      </div>
//                      <div>
//                        <h5>₹80</h5>
//                      </div>
//                    </div>
//                    <div className="d-flex gap-5">
//                      <div className="cart_text">
//                        <h6>Total Amount:</h6>
//                      </div>
//                      <div>
//                        <h5>₹{calculateTotal()}</h5>
//                      </div>
//                    </div>
//                  </div>
//                </div>
//                {/* ) :(<></>) } */}
//              </div>
//              <button className="d_button mt-3" type="submit">
//                Place order 
//              </button>
//            </div>
//          </form>
//        </div>
//      </div>
//   );
// };

// export default Addressdetails;


import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProductContext } from '../ProductContext';
import axios from 'axios';
import '../css/Addressdetails.css';

const Addressdetails = () => {

  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  
  const { cartItems = [], clearCart } = useContext(ProductContext);
  const navigate = useNavigate();

  const calculateTotal = () => {
    return cartItems.reduce((total, item) => total + item.new_price * item.quantity, 0) + 80;
  };

  const handlePay = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = {
      transactionId: `TXN${Date.now()}`,
      // Removed MUID field
      email: formData.get('email'),
      firstName: formData.get('firstName'),
      lastName: formData.get('lastName'),
      pincode: formData.get('pincode'),
      phoneNumber: formData.get('phoneNumber'),
      address: formData.get('address'),
      city: formData.get('city'),
      state: formData.get('state'),
      cartItems: cartItems.map(item => ({
        name: item.name,
        quantity: item.quantity,
        price: item.new_price * item.quantity
      })),
      amount: calculateTotal(),
    };
  
    try {
      const token = localStorage.getItem('auth-token');
      const res = await axios.post(`${BACKEND_URL}/submitAddress`, data, {
        headers: { 'auth-token': token }
      });

      if (res.data && res.data.data && res.data.data.instrumentResponse && res.data.data.instrumentResponse.redirectInfo) {
        const redirectUrl = res.data.data.instrumentResponse.redirectInfo.url;
        if (redirectUrl) {
          window.location.href = redirectUrl;
        } else {
          console.error('Redirect URL not found');
        }
      } else {
        console.error('Unexpected response structure:', res.data);
      }
    } catch (error) {
      console.error('Payment error:', error);
    }

    // Clear the cart after successful payment
    // clearCart();
  };

  return (
    <div className="container py-5">
      <div className="row">
        <form onSubmit={handlePay}>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-8">
                <h3 className="py-3">Add Shipping Address</h3>
                <input type="email" className="w-100 p-2 my-2" placeholder="Email" name="email" required />
                <div className="row">
                  <div className="col-md-6">
                    <input type="text" className="w-100 p-2 my-2" placeholder="First Name" name="firstName" required />
                  </div>
                  <div className="col-md-6">
                    <input type="text" className="w-100 p-2 my-2" placeholder="Last Name" name="lastName" required />
                  </div>
                  <div className="col-md-6">
                    <input type="number" className="w-100 p-2 my-2" placeholder="Pincode" name="pincode" required />
                  </div>
                  <div className="col-md-6">
                    <input type="number" className="w-100 p-2 my-2" placeholder="Phone Number" name="phoneNumber" required />
                  </div>
                </div>
                <textarea className="w-100 p-2 my-2" placeholder="Address" name="address" required></textarea>
                <div className="row">
                  <div className="col-md-6">
                    <input type="text" className="w-100 p-2 my-2" placeholder="City" name="city" required />
                  </div>
                  <div className="col-md-6">
                    <input type="text" className="w-100 p-2 my-2" placeholder="State" name="state" required />
                  </div>
                </div>
              </div>
              {localStorage.getItem('auth-token') ? (
              <div className="col-md-4 ordersummary_box">
                <h3>Order Summary</h3>
                <div className="row">
                  {cartItems.map((item, index) => (
                    <React.Fragment key={index}>
                      <div className="col-md-12 py-4">
                        <div className="row">
                          <div className="col-md-4">
                            <img src={item.image} alt={item.name} className="img-fluid cart_img" style={{ width: '100%' }} />
                          </div>
                          <div className="col-md-8">
                            <h5>{item.name}</h5>
                            <h6>Quantity: {item.quantity}</h6>
                            <h4>₹{item.new_price * item.quantity}</h4>
                          </div>
                        </div>
                      </div>
                      <hr />
                    </React.Fragment>
                  ))}
                  <div className="d-flex gap-5">
                    <div className="cart_text">
                      <h6>Shipping Fee:</h6>
                    </div>
                    <div>
                      <h5>₹80</h5>
                    </div>
                  </div>
                  <div className="d-flex gap-5">
                    <div className="cart_text">
                      <h6>Total Amount:</h6>
                    </div>
                    <div>
                      <h5>₹{calculateTotal()}</h5>
                    </div>
                  </div>
                </div>
              </div>
              ) : (<></>)}
            </div>
            {localStorage.getItem('auth-token') ? (
            <button className="d_button mt-3" type="submit">
              Pay ₹{calculateTotal()}
            </button>
             ) : (<></>)}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Addressdetails;








