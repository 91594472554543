import React, { useEffect, useState } from 'react';
import style from '../css/Newarrival.css';
import { NavLink } from "react-router-dom";

const Newarrival = () => {
   const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
   const scrollToTop = () => {
      window.scrollTo({
         top: 0,
         behavior: 'smooth'
      });
   };

   const [newArrivals, setNewArrivals] = useState([]);

   useEffect(() => {
      fetch(`${BACKEND_URL}/newarrivals`)
         .then((response) => response.json())
         .then((data) => setNewArrivals(data))
         .catch((error) => console.error('Error fetching new arrivals:', error));
   }, []);

   return (
      <div className="container">
         <div className="row my-4 justify-content-center">
            <h2 className="text-center my-4">NEW ARRIVALS</h2>
            {newArrivals.map((product) => (
               <div key={product._id} className="col-6 col-md-4 col-lg-3 d-flex flex-column category_box">
                  <NavLink to={`/Productdescription/${product.id}`} onClick={scrollToTop} className="text-decoration-none">
                     <div className="product_box">
                        <img src={product.image} alt={product.name} className='product_img' />
                        <p>{product.name}</p>
                        <p className='price_name'>₹{product.new_price}</p>
                        {/* <button className='product_btn'>Add to Cart</button> */}
                     </div>
                  </NavLink>
               </div>
            ))}
         </div>
      </div>
   );
};

export default Newarrival;
