import React from 'react'
import Shopbycategory from './Shopbycategory'
import Newarrival from './Newarrival'
import Descriptionadd from './Descriptionadd'
import Saleproduct from './Saleproduct'
import Featurebanner from './Featurebanner'
import Testimonials from './Testimonials'
import Blog from './Blog'
import Featurebox from './Featurebox'
import Herosection from './Herosection'
import Productss from './Productss'

const Home = () => {
    return (
        <>
            {/* <Productss /> */}
            <Herosection />
            <Shopbycategory />
            <Newarrival />
            <Descriptionadd />
            <Saleproduct />
            <Featurebanner />
            <Testimonials />
            {/* <Blog /> */}
            <Featurebox />
        </>

    )
}

export default Home