import React from 'react';
import style from '../css/Testimonials.css';
import { RiDoubleQuotesR } from 'react-icons/ri';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const Testimonials = () => {
  var settings = {
    autoplay: true,
    dots: false,
    adaptiveHeight: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <div className="container-fluid testimonial_pbox overflow-hidden">
        <h2 className="text-center my-4">TESTIMONIALS</h2>
        <div className="row ">

          <Slider {...settings}>
            <div>
              <div className="col-md-12 d-flex justify-content-center align-items-center py-4">
                <div className="testimonial_box ">
                  <div className="testi_symbol">
                    <RiDoubleQuotesR />
                  </div>
                  <p>
                  I keep purchasing crystals from here, the quality is very good , very cooperative & quick delivery.. the packaging is also very nice.
                  </p>
                  <div className="testi_upper">
                    <p className="mb-0 text-white">Soma Sadhukhan</p>
                  </div>
                </div>
              </div>
            </div>


            <div>
              <div className="col-md-12 d-flex justify-content-center align-items-center py-4">
                <div className="testimonial_box ">
                  <div className="testi_symbol">
                    <RiDoubleQuotesR />
                  </div>
                  <p>
                  I have recently bought Red coral gemstone from Lakshya Gems and trust me its purely original and affordable as well.
                  I would 100% recommend the outlet for ones who's looking for genuine gems or crystals.
                  </p>
                  <div className="testi_upper">
                    <p className="mb-0 text-white">Partho Dey</p>
                  </div>
                </div>
              </div>
            </div>


            <div>
              <div className="col-md-12 d-flex justify-content-center align-items-center py-4">
                <div className="testimonial_box ">
                  <div className="testi_symbol">
                    <RiDoubleQuotesR />
                  </div>
                  <p>
                  {/* My experience of purchasing crystals with Lakshya Gems is really great ,their products are authentic and service prompt and friendly.
                  I would surely recommend them to all looking for premium crystals and genstones. */}
                  My experience with Lakshya Gems was excellent. Their crystals are authentic, and their service is prompt and friendly. Highly recommend for premium crystals and gemstones!
                  </p>
                  <div className="testi_upper">
                    <p className="mb-0 text-white">Saurabh Srivastava</p>
                  </div>
                </div>
              </div>
            </div>


            <div>
              <div className="col-md-12 d-flex justify-content-center align-items-center py-4">
                <div className="testimonial_box ">
                  <div className="testi_symbol">
                    <RiDoubleQuotesR />
                  </div>
                  <p>
                  Highly satisfied with Lakshya Gems ! It is my go-to-place for buying good quality genuine crystals. The service is excellent and deliveries are always on time!
                  </p>
                  <div className="testi_upper">
                    <p className="mb-0 text-white">pawan nathawat</p>
                  </div>
                </div>
              </div>
            </div>

          </Slider>
        </div>
      </div>
    </>
  );
};

export default Testimonials;
