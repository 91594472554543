import React from 'react';
import shopcategory1 from '../assets/images/Crystal Bracelet.png';
import shopcategory2 from '../assets/images/Crystal Pyramid.png';
import shopcategory3 from '../assets/images/Crystal Tree.png';
import shopcategory4 from '../assets/images/Rudraksh.png';
import shopcategory5 from '../assets/images/Gemstone.png';
import style from '../css/Shopbycategory.css';
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const Shopbycategory = () => {

  const navigate = useNavigate();

  const handleCategoryClick = (category) => {
    navigate(`/category/${category}`);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const handleCategoryAndScroll = (category) => {
    handleCategoryClick(category);
    scrollToTop();
  };

  return (
    <div className="container">
      <div className="row my-4 justify-content-center">
        <h2 className="text-center my-4">SHOP BY CATEGORY</h2>
        <div className="col-6 col-md-4 col-lg-2 d-flex flex-column align-items-center category_box" onClick={() => handleCategoryAndScroll('Crystal Bracelet')}>
            <img src={shopcategory1} alt="" className="category_img" />
            <p className="text-center py-1 text-black">Crystal Bracelet</p>
        </div>
        <div className="col-6 col-md-4 col-lg-2 d-flex flex-column align-items-center category_box"  onClick={() => handleCategoryAndScroll('Crystal Pyramid')}>
            <img src={shopcategory2} alt="" className="category_img" />
            <p className="text-center py-1 text-black">Crystal Pyramid</p>
        </div>
        <div className="col-6 col-md-4 col-lg-2 d-flex flex-column align-items-center category_box"  onClick={() => handleCategoryAndScroll('Crystal Tree')}>
            <img src={shopcategory3} alt="" className="category_img" />
            <p className="text-center py-1 text-black">Crystal Tree</p>
        </div>
        <div className="col-6 col-md-4 col-lg-2 d-flex flex-column align-items-center category_box"  onClick={() => handleCategoryAndScroll('Rudraksh')}>
          <img src={shopcategory4} alt="" className="category_img" />
          <p className="text-center py-1 text-black">Rudraksh</p>
        </div>
        <div className="col-6 col-md-4 col-lg-2 d-flex flex-column align-items-center category_box"  onClick={() => handleCategoryAndScroll('Gemstone')}>
          <img src={shopcategory5} alt="" className="category_img" />
          <p className="text-center py-1 text-black">Gemstone</p>
        </div>
      </div>
    </div>
  );
};

export default Shopbycategory;
