import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const WhatsAppButton = () => {
  const handleWhatsAppClick = () => {
    const phoneNumber = '+917208383136'; // Replace with your phone number
    const message = 'Hello! I have a question.'; // Replace with your predefined message
    const encodedMessage = encodeURIComponent(message);
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

    window.open(whatsappLink, '_blank');
  };

  return (
    <button
      onClick={handleWhatsAppClick}
      className="btn btn-success rounded-circle position-fixed"
      style={{
        bottom: '20px',
        right: '15px',
        width: '50px',
        height: '50px',
        zIndex: '1040',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <FontAwesomeIcon icon={faWhatsapp} style={{ fontSize: '35px' }} />
    </button>
  );
};

export default WhatsAppButton;
