import React, { useContext } from 'react';
import style from '../css/Cart.css';
import { MdDelete } from "react-icons/md";
import { NavLink } from 'react-router-dom';
import { ProductContext } from '../ProductContext';

const Cart = () => {
    const { cartItems = [], clearCart, updateCartQuantity, removeFromCart } = useContext(ProductContext);


   
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const calculateTotal = () => {
        return cartItems.reduce((total, item) => total + item.new_price * item.quantity, 0);
    };

    return (
        <>
            <div className="container cart_table py-5">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-3 d-flex justify-content-center align-items-center">
                                <p>ITEM</p>
                            </div>
                            <div className="col-md-3 d-flex justify-content-center align-items-center">
                                <p>PRICE</p>
                            </div>
                            <div className="col-md-3 d-flex justify-content-center align-items-center">
                                <p>QUANTITY</p>
                            </div>
                            <div className="col-md-3 d-flex justify-content-center align-items-center">
                                <p>SUBTOTAL</p>
                            </div>
                        </div>
                    </div>
                    <hr />

                    {Array.isArray(cartItems) && cartItems.map((item, index) => (
                        <div key={index} className="col-md-12 pb-3">
                            <div className="row">
                                <div className="col-md-3 d-flex justify-content-center align-items-center gap-3">
                                    <div className="row">
                                        <div className="col-md-5 d-flex justify-content-center align-items-center">
                                            <img src={item.image} alt={item.name} className='img-fluid cart_img' />
                                        </div>
                                        <div className="col-md-7 d-flex justify-content-center align-items-center">
                                            <h6 className='fw-bold'>{item.name}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 d-flex justify-content-center align-items-center">
                                    <h6 className='fw-bold'>₹{item.new_price}</h6>
                                </div>
                                <div className="col-md-3 d-flex justify-content-center align-items-center">
                                    <div className='d-flex'>
                                        <div className="px-3 pluse q_box fw-bold" onClick={() => updateCartQuantity(item.id, item.quantity + 1)}>
                                            +
                                        </div>
                                        <div className="px-3 quantity_number q_box fw-bold">
                                            {item.quantity}
                                        </div>
                                        <div className="px-3 minus q_box fw-bold" onClick={() => updateCartQuantity(item.id, item.quantity - 1)}>
                                            -
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 d-flex justify-content-around align-items-center">
                                    <h6 className='fw-bold'>₹{item.new_price * item.quantity}</h6>
                                    <MdDelete style={{ backgroundColor: 'red', color: 'white', fontSize: '20px', borderRadius: '3px' }} onClick={() => removeFromCart(item.id)} />
                                </div>
                            </div>
                            <hr />
                        </div>
                    ))}

                </div>
            </div>

            {/* cart table for mobile device */}

            <div className="container mobile_cart_table pt-5">
                <div className="row">
                    {Array.isArray(cartItems) && cartItems.map((item, index) => (
                        <div key={index} className="row">
                            <div className="col-2 d-flex justify-content-center align-items-center">
                                <img src={item.image} alt={item.name} className='img-fluid cart_img' style={{ width: '100%' }} />
                            </div>
                            <div className="col-5">
                                <h6 className='fw-bold'>{item.name}</h6>
                                <h6 className='fw-bold'>₹{item.new_price}</h6>
                            </div>
                            <div className="col-3 d-flex flex-column justify-content-center">
                                <div className='d-flex'>
                                    <div className="px-1 pluse q_box fw-bold" onClick={() => updateCartQuantity(item.id, item.quantity + 1)}>
                                        +
                                    </div>
                                    <div className="px-1 quantity_number q_box fw-bold">
                                        {item.quantity}
                                    </div>
                                    <div className="px-1 minus q_box fw-bold" onClick={() => updateCartQuantity(item.id, item.quantity - 1)}>
                                        -
                                    </div>
                                </div>
                            </div>
                            <div className="col-2 d-flex justify-content-center align-items-center">
                                <MdDelete style={{ backgroundColor: 'red', color: 'white', fontSize: '20px', borderRadius: '3px' }} onClick={() => removeFromCart(item.id)} />
                            </div>
                            <hr />
                        </div>
                    ))}
                </div>
            </div>
            {/* cart table for mobile device end */}

            {cartItems.length > 0 ? (
                <div className="container py-3">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-12 d-flex justify-content-end">
                                    <button className='cart_clearbutton' onClick={clearCart}>Clear Shopping Cart</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}

            {cartItems.length > 0 ? (
                <div className="container py-3">
                    <div className="row">
                        <div className="col-md-12 d-flex gap-3 align-items-end flex-column ">
                            <div className='subtotal_box'>
                                <div className='d-flex gap-5'>
                                    <div className='cart_text'>
                                        <h6 className='fw-bold'>Subtotal :</h6>
                                    </div>
                                    <div>
                                        ₹{calculateTotal()}
                                    </div>
                                </div>
                                <div className='d-flex gap-5'>
                                    <div className='cart_text'>
                                        <h6>Shipping Fee :</h6>
                                    </div>
                                    <div>
                                        <p>₹80</p>
                                    </div>
                                </div>
                                <hr />
                                <div className='d-flex gap-5'>
                                    <div className='cart_text'>
                                        <h3>Order Total :</h3>
                                    </div>
                                    <div>
                                        <h3>₹{calculateTotal() + 80}</h3>
                                    </div>
                                </div>
                            </div>
                            <div className=''>
                                {localStorage.getItem('auth-token') ? (
                                    <button className='cart_orderbtn'>
                                        <NavLink to="/addressdetails" onClick={scrollToTop} className="text-decoration-none">
                                            <span className='text-white'>PLACE ORDER</span>
                                        </NavLink>
                                    </button>
                                ) : (
                                    <button className='cart_loginbtn'>
                                        <NavLink to="/login" onClick={scrollToTop} className="text-decoration-none">
                                            <span className='text-white'>LOGIN</span>
                                        </NavLink>
                                    </button>
                                )
                                }

                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <h5 className="text-center text-black my-5">Your Cart Is Currently Empty !</h5>
            )}
        </>
    );
}

export default Cart;




// import React, { useContext } from 'react';
// import { MdDelete } from "react-icons/md";
// import { NavLink } from 'react-router-dom';
// import { ProductContext } from '../ProductContext';
// import '../css/Cart.css';

// const Cart = () => {
//     const { cartItems = [], clearCart, updateCartQuantity, removeFromCart } = useContext(ProductContext);

//     const scrollToTop = () => {
//         window.scrollTo({
//             top: 0,
//             behavior: 'smooth'
//         });
//     };

//     const calculateTotal = () => {
//         return Array.isArray(cartItems) ? cartItems.reduce((total, item) => total + item.new_price * item.quantity, 0) : 0;
//     };

//     return (
//         <div className="container cart_table py-5">
//             <div className="row">
//                 <div className="col-md-12">
//                     <div className="row">
//                         <div className="col-md-3 d-flex justify-content-center align-items-center">
//                             <p>ITEM</p>
//                         </div>
//                         <div className="col-md-3 d-flex justify-content-center align-items-center">
//                             <p>PRICE</p>
//                         </div>
//                         <div className="col-md-3 d-flex justify-content-center align-items-center">
//                             <p>QUANTITY</p>
//                         </div>
//                         <div className="col-md-3 d-flex justify-content-center align-items-center">
//                             <p>SUBTOTAL</p>
//                         </div>
//                     </div>
//                 </div>
//                 <hr />

//                 {Array.isArray(cartItems) && cartItems.map((item, index) => (
//                     <div key={index} className="col-md-12 pb-3">
//                         <div className="row">
//                             <div className="col-md-3 d-flex justify-content-center align-items-center gap-3">
//                                 <img src={item.image} alt={item.name} className='img-fluid cart_img' />
//                                 <h6 className='fw-bold'>{item.name}</h6>
//                             </div>
//                             <div className="col-md-3 d-flex justify-content-center align-items-center">
//                                 <h6 className='fw-bold'>₹{item.new_price}</h6>
//                             </div>
//                             <div className="col-md-3 d-flex justify-content-center align-items-center">
//                                 <div className='d-flex'>
//                                     <div className="px-3 pluse q_box fw-bold" onClick={() => updateCartQuantity(item.id, item.quantity + 1)}>
//                                         +
//                                     </div>
//                                     <div className="px-3 q_box">{item.quantity}</div>
//                                     <div className="px-3 minus q_box fw-bold" onClick={() => updateCartQuantity(item.id, item.quantity - 1)}>
//                                         -
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="col-md-3 d-flex justify-content-center align-items-center">
//                                 <h6 className='fw-bold'>₹{item.new_price * item.quantity}</h6>
//                             </div>
//                             <div className='d-flex justify-content-end'>
//                                 <MdDelete size={22} className="text-danger" onClick={() => removeFromCart(item.id)} />
//                             </div>
//                         </div>
//                     </div>
//                 ))}
//                 <hr />
//                 <div className="col-md-12 pt-3">
//                     <div className="row">
//                         <div className="col-md-4 pt-3">
//                             <NavLink to='/allproducts' onClick={scrollToTop} className='custom_button2'>Continue Shopping</NavLink>
//                         </div>
//                         <div className="col-md-4 text-center pt-3">
//                             <button className='custom_button2' onClick={clearCart}>Clear Cart</button>
//                         </div>
//                         <div className="col-md-4 d-flex justify-content-center align-items-center pt-3">
//                             <div>
//                                 <h5 className='fw-bold'>Cart Total: ₹{calculateTotal()}</h5>
//                                 <NavLink to='/checkout' onClick={scrollToTop} className='custom_button2 mt-3'>Proceed to Checkout</NavLink>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Cart;
