import React from 'react';
import Bracelet from '../assets/images/Crystal Bracelet.png';
import Angels from '../assets/images/Angels.png';
import StoneStatue from '../assets/images/Stone Statue.png';
import Heart from '../assets/images/Heart.png';
import HomeDecor from '../assets/images/Home Decor.png';
import jaapmala from '../assets/images/jaap mala.png';
import shopcategory2 from '../assets/images/Crystal Pyramid.png';
import Stone from '../assets/images/Stones.png';
import Ring from '../assets/images/Ring.png';
import Pendulums from '../assets/images/Pendulums.png';
import CrystalPyramid from '../assets/images/Crystal Pyramid.png';
import Pendant from '../assets/images/Pendant.png';
import PencilWand from '../assets/images/Pencil Wand.png';
import RawStones from '../assets/images/Raw Stones.png';
import TumbleStone from '../assets/images/Tumble-Stone.png';
import Tree from '../assets/images/Crystal Tree.png';
import Ball from '../assets/images/ball.png';
import Vastuproduct from '../assets/images/vastuproduct.png';
import wishbox from '../assets/images/wishbox.png';
import btool from '../assets/images/btool.png';
import shopcategory4 from '../assets/images/Rudraksh.png';
import shopcategory5 from '../assets/images/Gemstone.png';
import Ganesh from '../assets/images/Ganesha.png';
import Cluster from '../assets/images/cluster.png';
import Candles from '../assets/images/Candles.png';
import { useNavigate } from 'react-router-dom';

const Categories = () => {
    const navigate = useNavigate();

    const handleCategoryClick = (category) => {
        navigate(`/category/${category}`);
    };

    return (
        <>
            <div className="container">
                <div className="row">
                    <h2 className="text-center my-4">CATEGORIES</h2>
                    <div className="col-6 col-md-4 col-lg-2 d-flex flex-column align-items-center category_box" onClick={() => handleCategoryClick('Angels')}>
                        <img src={Angels} alt="Angels" className="category_img" />
                        <p className="text-center py-1 text-black">Angels</p>
                    </div>
                    <div className="col-6 col-md-4 col-lg-2 d-flex flex-column align-items-center category_box" onClick={() => handleCategoryClick('Bracelet')}>
                        <img src={Bracelet} alt="Bracelet" className="category_img" />
                        <p className="text-center py-1 text-black">Bracelet</p>
                    </div>
                    <div className="col-6 col-md-4 col-lg-2 d-flex flex-column align-items-center category_box" onClick={() => handleCategoryClick('Candles')}>
                        <img src={Candles} alt="Candles" className="category_img" />
                        <p className="text-center py-1 text-black">Candles</p>
                    </div>
                    <div className="col-6 col-md-4 col-lg-2 d-flex flex-column align-items-center category_box" onClick={() => handleCategoryClick('Clusters')}>
                        <img src={Cluster} alt="Clusters" className="category_img" />
                        <p className="text-center py-1 text-black">Clusters</p>
                    </div>
                    <div className="col-6 col-md-4 col-lg-2 d-flex flex-column align-items-center category_box" onClick={() => handleCategoryClick('Ganesh')}>
                        <img src={Ganesh} alt="" className="category_img rounded" />
                        <p className="text-center py-1 text-black">Ganesh</p>
                    </div>

                    <div className="col-6 col-md-4 col-lg-2 d-flex flex-column align-items-center category_box" onClick={() => handleCategoryClick('Stone Statue')}>
                        <img src={StoneStatue} alt="" className="category_img" />
                        <p className="text-center py-1 text-black">Stone Statue</p>
                    </div>
                    <div className="col-6 col-md-4 col-lg-2 d-flex flex-column align-items-center category_box" onClick={() => handleCategoryClick('Heart')}>
                        <img src={Heart} alt="" className="category_img" />
                        <p className="text-center py-1 text-black">Heart</p>
                    </div>
                    <div className="col-6 col-md-4 col-lg-2 d-flex flex-column align-items-center category_box" onClick={() => handleCategoryClick('Home Decor')}>
                        <img src={HomeDecor} alt="" className="category_img" />
                        <p className="text-center py-1 text-black">Home Decor</p>
                    </div>

                    <div className="col-6 col-md-4 col-lg-2 d-flex flex-column align-items-center category_box" onClick={() => handleCategoryClick('Jaap Mala')}>
                        <img src={jaapmala} alt="" className="category_img" />
                        <p className="text-center py-1 text-black">Jaap Mala</p>
                    </div>
                    <div className="col-6 col-md-4 col-lg-2 d-flex flex-column align-items-center category_box" onClick={() => handleCategoryClick('Stones')}>
                        <img src={Stone} alt="" className="category_img" />
                        <p className="text-center py-1 text-black">Stones</p>
                    </div>
                    <div className="col-6 col-md-4 col-lg-2 d-flex flex-column align-items-center category_box" onClick={() => handleCategoryClick('Rings')}>
                        <img src={Ring} alt="" className="category_img" />
                        <p className="text-center py-1 text-black">Rings</p>
                    </div>
                    <div className="col-6 col-md-4 col-lg-2 d-flex flex-column align-items-center category_box" onClick={() => handleCategoryClick('Pendulum')}>
                        <img src={Pendulums} alt="" className="category_img" />
                        <p className="text-center py-1 text-black">Pendulum</p>
                    </div>

                    <div className="col-6 col-md-4 col-lg-2 d-flex flex-column align-items-center category_box" onClick={() => handleCategoryClick('Pyramid')}>
                        <img src={CrystalPyramid} alt="" className="category_img" />
                        <p className="text-center py-1 text-black">Pyramid</p>
                    </div>
                    <div className="col-6 col-md-4 col-lg-2 d-flex flex-column align-items-center category_box" onClick={() => handleCategoryClick('Pendant')}>
                        <img src={Pendant} alt="" className="category_img" />
                        <p className="text-center py-1 text-black">Pendant</p>
                    </div>
                    <div className="col-6 col-md-4 col-lg-2 d-flex flex-column align-items-center category_box" onClick={() => handleCategoryClick('Pencil Wand')}>
                        <img src={PencilWand} alt="" className="category_img" />
                        <p className="text-center py-1 text-black">Pencil Wand</p>
                    </div>

                    <div className="col-6 col-md-4 col-lg-2 d-flex flex-column align-items-center category_box" onClick={() => handleCategoryClick('Raw Stones')}>
                        <img src={RawStones} alt="" className="category_img" />
                        <p className="text-center py-1 text-black">Raw Stones</p>
                    </div>
                    <div className="col-6 col-md-4 col-lg-2 d-flex flex-column align-items-center category_box" onClick={() => handleCategoryClick('Tumble Stone')}>
                        <img src={TumbleStone} alt="" className="category_img" />
                        <p className="text-center py-1 text-black">Tumble Stone</p>
                    </div>
                    <div className="col-6 col-md-4 col-lg-2 d-flex flex-column align-items-center category_box" onClick={() => handleCategoryClick('Tree')}>
                        <img src={Tree} alt="" className="category_img" />
                        <p className="text-center py-1 text-black">Tree</p>
                    </div>

                    <div className="col-6 col-md-4 col-lg-2 d-flex flex-column align-items-center category_box" onClick={() => handleCategoryClick('Ball')}>
                        <img src={Ball} alt="" className="category_img" />
                        <p className="text-center py-1 text-black">Ball</p>
                    </div>
                    <div className="col-6 col-md-4 col-lg-2 d-flex flex-column align-items-center category_box" onClick={() => handleCategoryClick('Rudraksh')}>
                        <img src={shopcategory4} alt="" className="category_img" />
                        <p className="text-center py-1 text-black">Rudraksh</p>
                    </div>
                    <div className="col-6 col-md-4 col-lg-2 d-flex flex-column align-items-center category_box" onClick={() => handleCategoryClick('Vastu Product')}>
                        <img src={Vastuproduct} alt="" className="category_img" />
                        <p className="text-center py-1 text-black">Vastu Product</p>
                    </div>
                    <div className="col-6 col-md-4 col-lg-2 d-flex flex-column align-items-center category_box" onClick={() => handleCategoryClick('Wish Box')}>
                        <img src={wishbox} alt="" className="category_img" />
                        <p className="text-center py-1 text-black">Wish Box</p>
                    </div>
                    <div className="col-6 col-md-4 col-lg-2 d-flex flex-column align-items-center category_box" onClick={() => handleCategoryClick('Beauty Tools')}>
                        <img src={btool} alt="" className="category_img" />
                        <p className="text-center py-1 text-black">Beauty Tools</p>
                    </div>



                </div>
            </div>
        </>
    );
};

export default Categories;



