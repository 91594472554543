// import React from 'react'
// import style from '../css/Saleproduct.css'
// import Newarrival1 from '../assets/images/gemstone1.png'
// import Newarrival2 from '../assets/images/gemstone2.png'
// import Newarrival3 from '../assets/images/gemstone3.png'
// import Newarrival4 from '../assets/images/gemstone4.png'
// import { NavLink } from "react-router-dom";

// const Saleproduct = () => {
   
//   const scrollToTop = () => {
//     window.scrollTo({
//       top: 0,
//       behavior: 'smooth'
//     });
//   };

//   return (
//     <div className="container">
//       <div className="row my-4 justify-content-center">
//         <h2 className="text-center my-4">PRODUCTS ON OFFER</h2>
//         <div className="col-12 col-md-4 col-lg-3 d-flex flex-column  category_box">
//         <NavLink to="/productdescription" onClick={scrollToTop} className="text-decoration-none">
//          <div className="product_box">
//             <img src={Newarrival1} alt="" className='product_img' />
//             <p>Crystal Candles</p>
//             <p className='price_name'><span style={{ textDecoration: 'line-through',color:'red' }}>₹99</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;₹89</p>
//             <button className='product_btn'>Add to Cart</button>
//          </div>
//          </NavLink>
//         </div>
//         <div className="col-12 col-md-4 col-lg-3 d-flex flex-column  category_box">
//         <NavLink to="/productdescription" onClick={scrollToTop} className="text-decoration-none">
//          <div className="product_box">
//             <img src={Newarrival2} alt="" className='product_img' />
//             <p>Mini Crystal Trees</p>
//             <p className='price_name'><span style={{ textDecoration: 'line-through',color:'red' }}>₹119</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;₹99</p>
//             <button className='product_btn'>Add to Cart</button>
//          </div>
//          </NavLink>
//         </div>
//         <div className="col-12 col-md-4 col-lg-3 d-flex flex-column  category_box">
//         <NavLink to="/productdescription" onClick={scrollToTop} className="text-decoration-none">
//          <div className="product_box">
//             <img src={Newarrival3} alt="" className='product_img' />
//             <p>Round Elastic Bracelet</p>
//             <p className='price_name'><span style={{ textDecoration: 'line-through',color:'red' }}>₹350</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;₹299</p>
//             <button className='product_btn'>Add to Cart</button>
//          </div>
//          </NavLink>
//         </div>
//         <div className="col-12 col-md-4 col-lg-3 d-flex flex-column  category_box">
//         <NavLink to="/productdescription" onClick={scrollToTop} className="text-decoration-none">
//          <div className="product_box">
//             <img src={Newarrival4} alt="" className='product_img' />
//             <p>Rose Quartz Pendant</p>
//             <p className='price_name'><span style={{ textDecoration: 'line-through',color:'red' }}>₹1999</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;₹1499</p>
//             <button className='product_btn'>Add to Cart</button>
//          </div>
//          </NavLink>
//         </div>
       
        
       
//       </div>
//     </div>
//   )
// }

// export default Saleproduct



import React, { useState, useEffect } from 'react';
import style from '../css/Saleproduct.css';
import { NavLink } from "react-router-dom";

const Saleproduct = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    // Fetch products from the backend
    const fetchProducts = async () => {
      try {
        const response = await fetch(`${BACKEND_URL}/allproducts`);
        const data = await response.json();
        // Filter products that are on offer
        const filteredProducts = data.filter(product => product.productsonoffer);
        setProducts(filteredProducts);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching products:', error);
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container">
      <div className="row my-4 justify-content-center">
        <h2 className="text-center my-4">PRODUCTS ON OFFER</h2>
        {products.length === 0 ? (
          <div className="text-center">No products on offer</div>
        ) : (
          products.map(product => (
            <div key={product._id} className="col-6 col-md-4 col-lg-3 d-flex flex-column category_box">
              <NavLink to={`/Productdescription/${product.id}`} onClick={scrollToTop} className="text-decoration-none">
                <div className="product_box">
                  <img src={product.image} alt={product.name} className='product_img' />
                  <p>{product.name}</p>
                  <p className='price_name'>
                    <span style={{ textDecoration: 'line-through', color: 'red' }}>₹{product.old_price}</span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;₹{product.new_price}
                  </p>
                  {/* <button className='product_btn'>Add to Cart</button> */}
                </div>
              </NavLink>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default Saleproduct;
