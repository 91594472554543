import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

const ProductList = () => {
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
    const { categoryName } = useParams();
    const [products, setProducts] = useState([]);

    useEffect(() => {
        const fetchProductsByCategory = async () => {
            try {
                const response = await fetch(`${BACKEND_URL}/allproducts`);
                const data = await response.json();
                const filteredProducts = data.filter(product => product.category === categoryName);
                setProducts(filteredProducts);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        fetchProductsByCategory();
    }, [categoryName]);

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      };

    return (
        <div className="container mt-4">
            <div className="row">
                <h2 className="text-center my-4">{categoryName} Products</h2>
                {products.length > 0 ? (
                    products.map((product) => (
                        <div key={product._id} className="col-6 col-md-4 col-lg-3 d-flex flex-column category_box">
                            <NavLink to={`/Productdescription/${product.id}`} onClick={scrollToTop} className="text-decoration-none">
                                <div className="product_box">
                                    <img src={product.image} alt={product.name} className='product_img' />
                                    <p>{product.name}</p>
                                    <p className='price_name'>
                                        <span style={{ textDecoration: 'line-through', color: 'red' }}>₹{product.old_price}</span>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;₹{product.new_price}
                                    </p>
                                    {/* <button className='product_btn'>Add to Cart</button> */}
                                </div>
                            </NavLink>
                        </div>
                    ))
                ) : (
                    <p className="text-center text-black">No products available in this category.</p>
                )}
            </div>
        </div>
    );
};

export default ProductList;
