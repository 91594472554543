import React from 'react'
import style from '../css/Footer.css'
import facebook from '../assets/images/Facebook.png'
import instagram from '../assets/images/instagram.png'

const Footer = () => {
    return (
        <div className="container-fluid footer_box">
            <div className="row py-5">

                <div className="col-md-4 text-right text-md-center my-2">
                    <p>Social Networks</p>
                    <img className='social_img' src={facebook} alt="Facebook logo" />
                    <img className='social_img' src={instagram} alt="Instagram logo" />
                </div>
                <div className="col-md-4">
                    <p>About Us</p>
                    <p>Our Services</p>
                    <p>Our Gallery</p>
                    <p>Testimonials</p>
                    <p>Contact Us</p>
                </div>
                <div className="col-md-4">
                    <h3>CONTACT US</h3>
                    <h5>Address</h5>
                    <p>Shop no. 248 , 1st floor, Raghuleela mall, Kandivali, Jai Bhim Sanjay Nagar, Kandivali West, Mumbai, Maharashtra 400067</p>
                    <h5>Phone</h5>
                    <p>07208383136</p>
                </div>

            </div>
        </div>
    )
}

export default Footer
