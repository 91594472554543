import React from 'react'
import style from '../css/Featurebanner.css'
import featurebanner from '../assets/images/featurebanner.png'


const Featurebanner = () => {
  return (
   <div className="container-fluid">
    <div className="row">
        <div className="col-md-12">
           <img src={featurebanner} alt=""  className='img-fluid'/>
        </div>
    </div>
   </div>
  )
}

export default Featurebanner