import React from "react";
import Slider from "react-slick";
import Img1 from "../assets/images/hero1.png";
import Img2 from "../assets/images/hero2.png";
import Img3 from "../assets/images/hero3.png";
import Img4 from "../assets/images/hero4.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { NavLink } from "react-router-dom";

const Herosection = () => {
    var settings = {
        autoplay: true,
        dots: true,
        adaptiveHeight: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <div style={{ overflow: "hidden" }}>
            <Slider {...settings}>
                <div className="">
                    <img src={Img1} alt="" className=" img-fluid" />
                </div>

                <div className="">
                    <img src={Img2} alt="" className=" img-fluid" />
                </div>

                <div className="">
                    <img src={Img3} alt="" className=" img-fluid" />
                </div>

                <div className="">
                    <img src={Img4} alt="" className=" img-fluid" />
                </div>

            </Slider>
        </div>
    );
};

export default Herosection;
