import React, { useState } from 'react';
import { useRef } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import style from '../css/Navbar.css';
import { FaChevronDown } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
    const navRef = useRef();

    const showNavbar = () => {
        navRef.current.classList.toggle('responsive_nav');
    };

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const handleMouseEnter = () => {
        setDropdownOpen(true);
      };
    
      const handleMouseLeave = () => {
        setDropdownOpen(false);
      };

      const navigate = useNavigate();


      const handleCategoryClick = (category) => {
        navigate(`/category/${category}`);
        showNavbar();
    };


    return (
        <header className='d_only'>
           
            <nav ref={navRef}>
                <NavLink to="/"  onClick={showNavbar}><span className="font-weight-bold">HOME</span></NavLink>
                {/* <a href="/#">PRODUCTS</a> */}
                <NavLink to="/Products" onClick={showNavbar}>PRODUCTS</NavLink>
                <NavLink to="/Categories" onClick={showNavbar}>CATEGORIES</NavLink>
                {/* <div className="dropdown"
                 onMouseEnter = { handleMouseEnter }
                 onMouseLeave = { handleMouseLeave }
                >
                    <button className="dropbtn" onClick={toggleDropdown}>
                        CATEGORIES <FaChevronDown />
                    </button>
                    {dropdownOpen && (
                        <div className="dropdown-content">
                            <a href="/#">Angels</a>
                            <a href="/#">Bracelet </a>
                            <a href="/#">Candles</a>
                            <a href="">Clusters</a>
                            <a href="">Ganesh</a>
                            <a href="">Stone Statue</a>
                            <a href="">Heart</a>
                            <a href="">Home Decor</a>
                            <a href="">Jaap Mala</a>
                            <a href="">Stones</a>
                            <a href="">Rings </a>
                            <a href="">Pendulum</a>
                            <a href="">Pyramid</a>
                            <a href="">Pendant</a>
                            <a href="">Pencil Wand</a>
                            <a href="">Raw Stones</a>
                            <a href="">Tumble Stone</a>
                            <a href="">Tree</a>
                            <a href="">Ball</a>
                            <a href="">Rudraksh</a>
                            <a href="">Vastu Product</a>
                            <a href="">Wish Box</a>
                            <a href="">Beauty Tools</a>
                        </div>
                    )}
                </div> */}
                <div className='text_navb' onClick={() => handleCategoryClick('Crystal')} >CRYSTAL  </div>
                <div className='text_navb' onClick={() => handleCategoryClick('Gemstone')}>GEMSTONE  </div>
                <div className='text_navb' onClick={() => handleCategoryClick('Rudraksh')}>RUDRAKSHA  </div>
                <button className="nav-btn nav-close-btn" onClick={showNavbar}>
                    <FaTimes />
                </button>
            </nav>
           
            <button className="nav-btn" onClick={showNavbar}>
                <FaBars />
            </button>
           
            
        </header>
    );
};

export default Navbar;
