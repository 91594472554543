import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
const Paymentfailed = () => {
    return (
        <>
            <div className="container text-center my-5">

                <h4 className="alert-heading">Payment Failed!</h4>
                <p>Unfortunately, your payment could not be processed at this time. Please try again.</p>
            </div>
            <div className='text-center my-5'>
                <NavLink to="/" className="d_button text-decoration-none">
                    Go Back to Home
                </NavLink>
            </div>

        </>

    )
}

export default Paymentfailed