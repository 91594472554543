import React, { useContext, useEffect } from 'react';
import { useParams, NavLink, useNavigate } from 'react-router-dom';
import { ProductContext } from '../ProductContext';
import style from '../css/Productdescription.css';
import { useState } from 'react';
import Reviewg from './Reviewg';

const Productdescription = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { fetchProductDetails, productDetails, addToCart } = useContext(ProductContext);
    const [quantity, setQuantity] = useState(1);

    useEffect(() => {
        fetchProductDetails(id);
    }, [id, fetchProductDetails]);

    if (!productDetails) {
        return <div>Loading...</div>;
    }

    const handleBackClick = () => {
        navigate(-1); 
    };

    const handleAddToCart = () => {
        addToCart({ ...productDetails, quantity });
        navigate('/cart');
    };

    const increaseQuantity = () => {
        if (quantity < 5) {
            setQuantity(quantity + 1);
        }
    };

    const decreaseQuantity = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      };

    return (
        <>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <button className='d_button' onClick={handleBackClick}>
                        <span className='text-white'>BACK TO PRODUCTS</span>
                    </button>
                </div>
            </div>
            <div className="row py-3">
                <div className="col-md-12 py-4">
                    <div className="row">
                        <div className="col-md-6">
                            <img src={productDetails.image} alt={productDetails.name} className='img-fluid d_img' />
                        </div>
                        <div className="col-md-6 p-3">
                            <h2>{productDetails.name}</h2>
                            <h3>₹{productDetails.new_price}</h3>
                            <p>{productDetails.description}</p>
                            <div className="stock_box d-flex gap-3">
                                <h5>Category :</h5>
                                <p>{productDetails.category}</p>
                            </div>
                            <div className="stock_box d-flex gap-3">
                                <h5>Stock :</h5>
                                <p>In Stock</p>
                            </div>
                            <div className='quantity_box d-flex gap-3'>
                                <h5>Quantity :</h5>
                                <div className='d-flex'>
                                    <div className="px-3 pluse q_box fw-bold" onClick={increaseQuantity}>
                                        +
                                    </div>
                                    <div className="px-3 quantity_number q_box fw-bold">
                                    {quantity}
                                    </div>
                                    <div className="px-3 minus q_box fw-bold" onClick={decreaseQuantity} >
                                        -
                                    </div>
                                </div>
                            </div>
                            <button className='d_button' onClick={handleAddToCart}>
                                <span className='text-white' onClick={scrollToTop}>ADD TO CART</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Reviewg />
        </>
    );
}

export default Productdescription;


