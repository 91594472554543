import React, { useContext, useState } from 'react';
import { FaSearch } from "react-icons/fa";
import { LuShoppingCart } from "react-icons/lu";
import { ProductContext } from '../ProductContext';
import style from '../css/Topnavber.css';
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/images/logo_lakshyagems.png'
import Navbar from './Navbar';
import Mobiledropdownmenu from './Mobiledropdownmenu';

const Topnavber = () => {
    const { clearCart } = useContext(ProductContext);

    const navigate = useNavigate();
    const logout = () => {
        localStorage.removeItem('auth-token');
        navigate('/');
        clearCart();
    };


    const { cartItems, searchProducts } = useContext(ProductContext);
    const [searchQuery, setSearchQuery] = useState("");

    const handleSearch = (e) => {
        e.preventDefault();
        searchProducts(searchQuery);
        navigate('/productss');
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <div className="container-fluid topnav_bar">
            <div className="row">
                <div className="col-md-12 py-3">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-4 topnavbarlogo_box">
                                <NavLink to="/" onClick={scrollToTop} className="text-decoration-none text-black">
                                    <img src={logo} className='topnavbar_logo' alt="" />
                                </NavLink>
                            </div>
                            <div className="col-md-4 d-flex align-items-center">
                                <form className="example" onSubmit={handleSearch}>
                                    <input
                                        type="text"
                                        placeholder="Search.."
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                    />
                                    <button type="submit"><FaSearch /></button>
                                </form>
                            </div>
                            <div className="col-md-4 cart-container d-flex justify-content-between justify-content-md-around">
                                {localStorage.getItem('auth-token') ? (
                                    <button onClick={logout} className='d_button my-0'>
                                        <span className='text-white'>LOGOUT</span>
                                    </button>
                                   
                                ) : (
                                    <NavLink to="/login" onClick={scrollToTop} className="text-decoration-none">
                                        <button className='d_button my-0'>
                                            <span className='text-white'>LOGIN</span>
                                        </button>
                                    </NavLink>
                                )}
                                
                                {localStorage.getItem('auth-token') ? (
                                     <NavLink to="/myorders" onClick={scrollToTop} className="text-decoration-none">
                                     <button className='d_button my-0'>
                                     <span className='text-white'>ORDERS</span>
                                 </button>
                                 </NavLink>
                                ) : ( <></> )}
                                {/* <button className='d_button my-0'>
                                    <span className='text-white'>ORDERS</span>
                                </button> */}

                                {/* <div className='cart_icon_pbox'>
                                    <div className="cart-icon-wrapper">
                                        <NavLink to="/cart" onClick={scrollToTop} className="text-decoration-none text-black">
                                            <LuShoppingCart className='cart-icon' />
                                            <span className="cart-count">{cartItems.length}</span>
                                        </NavLink>
                                    </div>
                                </div> */}
                                <div className='mobileresponsive d-flex align-items-center justify-content-center gap-3'>
                                <div className='cart_icon_pbox'>
                                    <div className="cart-icon-wrapper">
                                        <NavLink to="/cart" onClick={scrollToTop} className="text-decoration-none text-black">
                                            <LuShoppingCart className='cart-icon' />
                                            <span className="cart-count">{cartItems.length}</span>
                                        </NavLink>
                                    </div>
                                </div>
                                <div className='d-block d-md-none'>
                                    <Mobiledropdownmenu />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Topnavber;


