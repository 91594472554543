import React from 'react'
import style from '../css/Descriptionadd.css'

const Descriptionadd = () => {
    return (
        <div className="container-fluid d_addbox">
            <div className="row my-4">
                <div className="col-md-12">
                    <h5 className='text-center py-4'>Buy Gemstones Online - Precious Stones, Semi-Precious Stones, Astrological Stones or Rashi Ratna. 100% Original & Natural</h5>
                </div>
            </div>
        </div>
    )
}

export default Descriptionadd