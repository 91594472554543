

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import style from '../css/Myorders.css';

const MyOrders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);  
  const [error, setError] = useState(null);
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;    

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const token = localStorage.getItem('auth-token'); 
        if (!token) {
          throw new Error('No authentication token found');
        }
        
        const response = await axios.get(`${BACKEND_URL}/getAddresses`, {
          headers: {
            'auth-token': token, 
          },
        });

        if (response.data && response.data.orders) {
          setOrders(response.data.orders); 
        } else {
          setOrders([]);
        }
      } catch (error) {
        console.error('Error fetching orders:', error);
        setError('Failed to fetch orders. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  return (
    <div className="container">
      <h2 className="text-center my-4">MY ORDERS</h2>
      {loading ? (
        <p className='text-center'>Loading...</p>
      ) : error ? (
        <p className='text-center'>{error}</p>
      ) : orders.length > 0 ? (
        orders.map((order, index) => (
          <div key={index} className="row m-3">
            <div className="col-md-12 order_box">
              <div className="row my-3">
                <div className="col-md-1 d-flex align-items-center">
                  <img
                    width="48"
                    height="48"
                    src="https://img.icons8.com/fluency/48/product.png"
                    alt="product"
                  />
                </div>
                <div className="col-md-5 d-flex align-items-center">
                  <h6>
                    {order.cartItems.map((item, idx) => (
                      <span key={idx}>
                        {item.name} * {item.quantity}
                        {idx < order.cartItems.length - 1 ? ', ' : ''}
                      </span>
                    ))}
                  </h6>
                </div>
                <div className="col-md-2 d-flex align-items-center">
                  <div className="d-flex justify-content-center align-items-center">
                    <p className="mb-0">Price:</p>
                    <p className="mb-0 font-weight-bold">&nbsp;₹ {order.amount}</p>
                  </div>
                </div>
                <div className="col-md-2 d-flex align-items-center">
                  <div className="d-flex justify-content-center align-items-center py-1">
                    <p className="mb-0">Item:</p>
                    <p className="mb-0 font-weight-bold">&nbsp;{order.cartItems.length}</p>
                  </div>
                </div>
                <div className="col-md-2 d-flex align-items-center">
                  <div className="d-flex py-1">
                    <img
                      width="20"
                      height="20"
                      src="https://img.icons8.com/ios-glyphs/30/full-stop--v1.png"
                      alt="full-stop--v1"
                    />
                    <h6>{order.status}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <p className='text-center'>No orders found</p>
      )}
    </div>
  );
};

export default MyOrders;




