// import React, { createContext, useState, useEffect } from 'react';

// export const ProductContext = createContext();

// export const ProductProvider = ({ children }) => {
//     const [products, setProducts] = useState([]);
//     const [filteredProducts, setFilteredProducts] = useState([]);
//     const [productDetails, setProductDetails] = useState(null);
//     const [cartItems, setCartItems] = useState([]);

//     useEffect(() => {
//         fetch('${BACKEND_URL}/allproducts')
//             .then(response => response.json())
//             .then(data => setProducts(data))
//             .catch(error => console.error('Error fetching products:', error));

//         if (localStorage.getItem('auth-token')) {
//             fetch('${BACKEND_URL}/getcart', {
//                 method: 'POST',
//                 headers: {
//                     Accept: 'application/form-data',
//                     'auth-token': `${localStorage.getItem('auth-token')}`,
//                     'Content-Type': 'application/json'
//                 },
//                 body: "",
//             }).then((response) => response.json())
//                 .then((data) => console.log(data));
//         }
//     }, []);

//     const fetchProductDetails = (id) => {
//         const product = products.find(prod => prod.id === parseInt(id));
//         if (product) {
//             setProductDetails(product);
//         } else {
//             console.error('Product not found');
//         }
//     };

//     const searchProducts = (query) => {
//         if (query === "") {
//             setFilteredProducts([]);
//         } else {
//             setFilteredProducts(products.filter(product =>
//                 product.name.toLowerCase().includes(query.toLowerCase())
//             ));
//         }
//     };

//     const addToCart = (product) => {
//         setCartItems((prevItems) => {
//             if (!Array.isArray(prevItems)) {
//                 prevItems = [];
//             }
//             const existingItem = prevItems.find((item) => item.id === product.id);
//             if (existingItem) {
//                 return prevItems.map((item) =>
//                     item.id === product.id ? { ...item, quantity: item.quantity + (product.quantity || 1) } : item
//                 );
//             } else {
//                 return [...prevItems, { ...product, quantity: product.quantity || 1 }];
//             }


//         });

//         if (localStorage.getItem('auth-token')) {
//             fetch('${BACKEND_URL}/addtocart', {
//                 method: 'POST',
//                 headers: {
//                     Accept: 'application/json',
//                     'auth-token': `${localStorage.getItem('auth-token')}`,
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify({ id: product.id }),
//             })
//                 .then((response) => response.json())
//                 .then((data) => console.log(data))
//                 .catch((error) => console.error('Error adding to cart:', error));
//         }
//     };

//     const clearCart = () => {
//         setCartItems([]);
//         if (localStorage.getItem('auth-token')) {
//             fetch('${BACKEND_URL}/clearcart', {
//                 method: 'POST',
//                 headers: {
//                     Accept: 'application/json',
//                     'auth-token': localStorage.getItem('auth-token'),
//                     'Content-Type': 'application/json',
//                 }
//             })
//                 .then(response => response.json())
//                 .then(data => console.log(data))
//                 .catch(error => console.error('Error clearing cart:', error));
//         }
//     };

//     const removeFromCart = (id) => {
//         setCartItems((prevItems) => prevItems.filter((item) => item.id !== id));
//         if (localStorage.getItem('auth-token')) {
//             fetch('${BACKEND_URL}/removefromcart', {
//                 method: 'POST',
//                 headers: {
//                     Accept: 'application/json',
//                     'auth-token': localStorage.getItem('auth-token'),
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify({ id }),
//             })
//                 .then(response => response.json())
//                 .then(data => console.log(data))
//                 .catch(error => console.error('Error removing from cart:', error));
//         }
//     };

//     const updateCartQuantity = (id, quantity) => {
//         setCartItems((prevItems) =>
//             prevItems.map((item) =>
//                 item.id === id ? { ...item, quantity: Math.min(Math.max(quantity, 1), 5) } : item
//             )
//         );

//         if (localStorage.getItem('auth-token')) {
//             fetch('${BACKEND_URL}/updatecartquantity', {
//                 method: 'POST',
//                 headers: {
//                     Accept: 'application/json',
//                     'auth-token': localStorage.getItem('auth-token'),
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify({ id, quantity }),
//             })
//                 .then(response => response.json())
//                 .then(data => console.log(data))
//                 .catch(error => console.error('Error updating cart quantity:', error));
//         }
//     };

//     return (
//         <ProductContext.Provider value={{
//             products,
//             filteredProducts,
//             searchProducts,
//             fetchProductDetails,
//             productDetails,
//             cartItems,
//             addToCart,
//             clearCart,
//             removeFromCart,
//             updateCartQuantity
//         }}>
//             {children}
//         </ProductContext.Provider>
//     );
// };


import React, { createContext, useState, useEffect } from 'react';

export const ProductContext = createContext();

export const ProductProvider = ({ children }) => {
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [productDetails, setProductDetails] = useState(null);
    const [cartItems, setCartItems] = useState(() => {
        const savedCartItems = localStorage.getItem('cartItems');
        return savedCartItems ? JSON.parse(savedCartItems) : [];
    });

    const [orders, setOrders] = useState([]);

    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

    useEffect(() => {
        fetch(`${BACKEND_URL}/allproducts`)
            .then(response => response.json())
            .then(data => setProducts(data))
            .catch(error => console.error('Error fetching products:', error));
    }, []);


    


    useEffect(() => {
        const fetchOrders = async () => {
            if (localStorage.getItem('auth-token')) {
                try {
                    const response = await fetch(`${BACKEND_URL}/myorders`, {
                        method: 'GET',
                        headers: {
                            Accept: 'application/json',
                            'auth-token': localStorage.getItem('auth-token'),
                        },
                    });
                    const orderData = await response.json();
                    if (orderData.success) {
                        setOrders(orderData.orders); // Orders now include product descriptions
                    }
                } catch (error) {
                    console.error('Error fetching orders:', error);
                }
            }
        };
    
        fetchOrders();
    }, []);

    useEffect(() => {
        localStorage.setItem('cartItems', JSON.stringify(cartItems));
    }, [cartItems]);

    const mergeCartItems = (localCart, serverCart) => {
        const mergedCart = [...localCart];

        serverCart.forEach(serverItem => {
            const existingItemIndex = mergedCart.findIndex(item => item.id === serverItem.id);
            if (existingItemIndex !== -1) {
                mergedCart[existingItemIndex].quantity += serverItem.quantity;
            } else {
                mergedCart.push(serverItem);
            }
        });

        return mergedCart;
    };

    const fetchProductDetails = (id) => {
        const product = products.find(prod => prod.id === parseInt(id));
        if (product) {
            setProductDetails(product);
        } else {
            console.error('Product not found');
        }
    };

    const searchProducts = (query) => {
        if (query === "") {
            setFilteredProducts([]);
        } else {
            setFilteredProducts(products.filter(product =>
                product.name.toLowerCase().includes(query.toLowerCase())
            ));
        }
    };

    const addToCart = async (product) => {
        setCartItems((prevItems) => {
            const existingItem = prevItems.find((item) => item.id === product.id);
            if (existingItem) {
                return prevItems.map((item) =>
                    item.id === product.id ? { ...item, quantity: item.quantity + (product.quantity || 1) } : item
                );
            } else {
                return [...prevItems, { ...product, quantity: product.quantity || 1 }];
            }
        });

        if (localStorage.getItem('auth-token')) {
            try {
                await fetch(`${BACKEND_URL}/addtocart`, {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'auth-token': localStorage.getItem('auth-token'),
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ id: product.id, quantity: product.quantity || 1 }),
                });
            } catch (error) {
                console.error('Error adding to cart:', error);
            }
        }
    };

    const clearCart = async () => {
        setCartItems([]);
        if (localStorage.getItem('auth-token')) {
            try {
                await fetch(`${BACKEND_URL}/clearcart`, {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'auth-token': localStorage.getItem('auth-token'),
                        'Content-Type': 'application/json',
                    },
                });
            } catch (error) {
                console.error('Error clearing cart:', error);
            }
        }
    };

    const removeFromCart = async (id) => {
        setCartItems((prevItems) => prevItems.filter((item) => item.id !== id));

        if (localStorage.getItem('auth-token')) {
            try {
                await fetch(`${BACKEND_URL}/removefromcart`, {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'auth-token': localStorage.getItem('auth-token'),
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ id }),
                });
            } catch (error) {
                console.error('Error removing from cart:', error);
            }
        }
    };

    const updateCartQuantity = async (id, quantity) => {
        setCartItems((prevItems) =>
            prevItems.map((item) =>
                item.id === id ? { ...item, quantity: Math.min(Math.max(quantity, 1), 5) } : item
            )
        );

        if (localStorage.getItem('auth-token')) {
            try {
                await fetch(`${BACKEND_URL}/updatecartquantity`, {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'auth-token': localStorage.getItem('auth-token'),
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ id, quantity }),
                });
            } catch (error) {
                console.error('Error updating cart quantity:', error);
            }
        }
    };

    return (
        <ProductContext.Provider value={{
            products,
            filteredProducts,
            searchProducts,
            fetchProductDetails,
            productDetails,
            cartItems,
            addToCart,
            clearCart,
            removeFromCart,
           
            updateCartQuantity
        }}>
            {children}
        </ProductContext.Provider>
    );
};


























