import React from 'react';
import style from '../css/Featurebox.css';
import Feature1 from '../assets/images/feature1.png';
import Feature2 from '../assets/images/feature2.png';
import Feature3 from '../assets/images/feature3.png';
import Feature4 from '../assets/images/feature4.png';

const Featurebox = () => {
    return (
        <div className="container-fluid features_box ">
            <div className="row">
                <div className="col-md-12">
                    <div className="mainfeaturebox border-top border-bottom border-danger border-2">
                        <div className="row">
                            <div className="col-md-3 d-flex">
                                <img src={Feature1} alt="" />
                                <div className=' d-flex flex-column justify-content-center'>
                                <h5>Over 5000+</h5>
                                <p className='feature_para'>Products Available</p>
                                </div>
                            </div>
                            <div className="col-md-3 d-flex">
                                <img src={Feature2} alt="" />
                                <div className=' d-flex flex-column justify-content-center'>
                                <h5>Secure Payments</h5>
                                <p className='feature_para'>100% Secured Payment</p>
                                </div>
                            </div>
                            <div className="col-md-3 d-flex">
                                <img src={Feature3} alt="" />
                                <div className=' d-flex flex-column justify-content-center'>
                                <h5>Speedy Delivery</h5>
                                <p className='feature_para'>Within 7 days (T&C Apply)</p>
                                </div>
                            </div>
                            <div className="col-md-3 d-flex">
                                <img src={Feature4} alt="" />
                                <div className=' d-flex flex-column justify-content-center'>
                                <h5>We Support</h5>
                                <p className='feature_para'>Monday to Sunday</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Featurebox;
