import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from './Components/Footer';
import Productdescription from './Components/Productdescription';
import Cart from './Components/Cart';
import Addressdetails from './Components/Addressdetails';
import Signup from './Components/Signup';
import Login from './Components/Login';
import Products from './Components/Products';
import Home from './Components/Home';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from './Components/Navbar';
import Categories from './Components/Categories';
import Topnavber from './Components/Topnavber';
import WhatsAppButton from './Components/WhatsAppButton';
import { ProductProvider } from './ProductContext';
import ProductList from './Components/ProductList';
import Productss from './Components/Productss';
import Myorders from './Components/Myorders';
import Mobiledropdownmenu from './Components/Mobiledropdownmenu';
import Paymentfailed from './Components/Paymentfailed';
import Reviewg from './Components/Reviewg';


function App() {
  return (
    <>
      <ProductProvider>
        <div className="App">
          <Router>
            <Topnavber />
            <Navbar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/" element={<Mobiledropdownmenu />} />
              <Route path="/productss" element={<Productss />} />
              <Route path="/Productdescription/:id" element={<Productdescription />} />
              <Route path="/products" element={<Products />} />
              <Route path="/cart" element={<Cart />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/login" element={<Login />} />
              <Route path="/addressdetails" element={<Addressdetails />} />
              <Route path="/categories" element={<Categories />} />
              <Route path="/category/:categoryName" element={<ProductList />} />
              <Route path="/myorders" element={<Myorders />} />
              <Route path="/paymentfailed" element={<Paymentfailed />} />
              <Route path="/reviewg" element={<Reviewg />} />
            </Routes>
          </Router>
          <Footer />
          <WhatsAppButton />
        </div>
      </ProductProvider>

    </>
  );
}

export default App;
