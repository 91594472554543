import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

const Login = () => {
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
    const [formData, setFormData] = useState({
        email: "",
        password: ""
    });

    const navigate = useNavigate();

    const changeHandler = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const login = async (e) => {
        e.preventDefault();
        console.log("login function executed", formData);
        let responseData;
        try {
            const response = await fetch(`${BACKEND_URL}/login`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
            responseData = await response.json();

            if (responseData.success) {
                localStorage.setItem('auth-token', responseData.token);
                navigate("/");
            } else {
                alert(responseData.errors);
            }
        } catch (error) {
            console.error("Error during login:", error);
            alert("An error occurred. Please try again.");
        }
    };

    return (
        <div className="container-fluid signup_box">
            <div className="row">
                <div className="col-md-12 bg-white p-5">
                    <h5>LOGIN</h5>
                    <form onSubmit={login}>
                        <input
                            name='email'
                            value={formData.email}
                            onChange={changeHandler}
                            type="email"
                            placeholder='Email Address'
                            className='w-100 my-3 p-2'
                            required
                        />
                        <input
                            name='password'
                            value={formData.password}
                            onChange={changeHandler}
                            type="password"
                            placeholder='Password'
                            className='w-100 my-3 p-2'
                            required
                            minLength="6"
                        />
                        <button type='submit' className='login_btn w-100'>
                            <span className='text-white'>Continue</span>
                        </button>
                    </form>
                    <p className='py-2 text-center'>
                        Create an account? <NavLink to="/signup" className="text-decoration-none"><span>Click here</span></NavLink>
                    </p>
                    {/* <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="flexCheckIndeterminate" />
                        <label className="form-check-label" htmlFor="flexCheckIndeterminate">
                            By continuing, I agree to the terms of use & privacy policy.
                        </label>
                    </div> */}
                </div>
            </div>
        </div>
    );
}

export default Login;






